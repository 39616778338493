import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import List from '../../lists/List';

const Languages = ({ id, event }) => {
  const path = `${id}.items`;
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex justify-between pt-3">
        <Heading id={id} />
      </div>

      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <List path={path} event={event} titlePath="name" subtitlePath="fluency" />
    </section>
  );
};

export default memo(Languages);
