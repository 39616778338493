import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { AiFillQuestionCircle, AiFillCloseCircle } from 'react-icons/ai';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import LinearProgress from '@mui/material/LinearProgress';
import ModalContext from '../../contexts/ModalContext';
import ModalEvents from '../../constants/ModalEvents';
import { useSelector } from '../../contexts/ResumeContext';
import * as styles from './Completeness.module.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const Completeness = ({ completeness, doScroll }) => {
  const { t } = useTranslation();
  const { emitter } = useContext(ModalContext);
  const stateValue = useSelector('completeness', '');

  completeness = stateValue;

  const params = [
    {
      rate: '25',
      label: '+25% Add employment history',
      event: ModalEvents.WORK_MODAL,
    },
    {
      rate: '15',
      label: '+15% Add profile summary',
    },
    {
      rate: '15',
      label: '+15% Add education',
      event: ModalEvents.EDUCATION_MODAL,
    },
    {
      rate: '10',
      label: '+10% Add job title',
    },
    {
      rate: '5',
      label: '+5% Add email',
    },
    {
      rate: '4',
      label: '+4% Add skills',
      event: ModalEvents.SKILL_MODAL,
    },
    {
      rate: '3',
      label: '+3% Add languages',
      event: ModalEvents.LANGUAGE_MODAL,
    },
    {
      rate: '2',
      label: '+2% Add a city name',
    },
    {
      rate: '2',
      label: '+2% Add internships',
      event: ModalEvents.PROJECT_MODAL,
    },
    {
      rate: '1',
      label: '+1% Add a phone number',
    },
    {
      rate: '4',
      label: '+4% Add extra-curricular activities',
      event: ModalEvents.EDUCATION_MODAL,
    },
    {
      rate: '4',
      label: '+4% Add languages',
      event: ModalEvents.LANGUAGE_MODAL,
    },
  ];

  const handleClick = (e) => {
    if (
      e.value === '1' ||
      e.value === '3' ||
      e.value === '4' ||
      e.value === '7' ||
      e.value === '9'
    ) {
      let targetName;
      if (e.value === '1') targetName = 'objective';
      else targetName = 'profile';
      doScroll(targetName);
    } else {
      emitter.emit(params[e.value].event);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`focus:outline-none font-medium ${styles.completeness}`}>
        <span>
          {completeness < 30 && (
            <span className={styles.percentage_color}>{completeness}% </span>
          )}
          {completeness >= 30 && completeness < 70 && (
            <span className={styles.percentage_color2}>{completeness}% </span>
          )}
          {completeness >= 70 && (
            <span className={styles.percentage_color3}>{completeness}% </span>
          )}
          {t(`builder.completeness.label`)}
        </span>
        <Menu
          menuButton={({ open }) => (
            <MenuButton>
              {!open && (
                <AiFillQuestionCircle size={20} className={styles.icon} />
              )}
              {open && (
                <AiFillCloseCircle size={20} className={styles.icon_close} />
              )}
            </MenuButton>
          )}
          onItemClick={handleClick}
        >
          <MenuItem value="0">
            <span className="menu-item-strong">+25%</span>&nbsp;Add employment
            history
          </MenuItem>
          <MenuItem value="1">
            <span className="menu-item-strong">+15%</span>&nbsp;Add profile
            summary
          </MenuItem>
          <MenuItem value="2">
            <span className="menu-item-strong">+15%</span>&nbsp;Add education
          </MenuItem>
          <MenuItem value="3">
            <span className="menu-item-strong">+10%</span>&nbsp;Add job title
          </MenuItem>
          <MenuItem value="4">
            <span className="menu-item-strong">+5%</span>&nbsp;Add email
          </MenuItem>
          <MenuItem value="5">
            <span className="menu-item-strong">+4%</span>&nbsp;Add skills
          </MenuItem>
          <MenuItem value="6">
            <span className="menu-item-strong">+3%</span>&nbsp;Add languages
          </MenuItem>
          <MenuItem value="7">
            <span className="menu-item-strong">+2%</span>&nbsp;Add a city name
          </MenuItem>
          <MenuItem value="8">
            <span className="menu-item-strong">+2%</span>&nbsp;Add internships
          </MenuItem>
          <MenuItem value="9">
            <span className="menu-item-strong">+1%</span>&nbsp;Add a phone
            number
          </MenuItem>
        </Menu>
      </div>
      {completeness < 30 && (
        <LinearProgress
          variant="determinate"
          classes={{
            colorPrimary: styles.bar_bgcolor,
            barColorPrimary: styles.bar_color,
          }}
          value={completeness}
        />
      )}
      {completeness >= 30 && completeness < 70 && (
        <LinearProgress
          variant="determinate"
          classes={{
            colorPrimary: styles.bar_bgcolor,
            barColorPrimary: styles.bar_color2,
          }}
          value={completeness}
        />
      )}
      {completeness >= 70 && (
        <LinearProgress
          variant="determinate"
          classes={{
            colorPrimary: styles.bar_bgcolor,
            barColorPrimary: styles.bar_color3,
          }}
          value={completeness}
        />
      )}
    </div>
  );
};

export default memo(Completeness);
