// extracted by mini-css-extract-plugin
export var container = "Completeness-module--container--3Gw7E";
export var completeness = "Completeness-module--completeness--3rtSX";
export var percentage_color = "Completeness-module--percentage_color--3RxN5";
export var percentage_color2 = "Completeness-module--percentage_color2--1cePH";
export var percentage_color3 = "Completeness-module--percentage_color3--1iaAK";
export var bar_color = "Completeness-module--bar_color--1ITUk";
export var bar_color2 = "Completeness-module--bar_color2--2_KDd";
export var bar_color3 = "Completeness-module--bar_color3--f-XRl";
export var bar_bgcolor = "Completeness-module--bar_bgcolor--CsvSB";
export var icon = "Completeness-module--icon--215c2";
export var icon_close = "Completeness-module--icon_close--3X7EI";