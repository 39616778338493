import { Element, scroller } from 'react-scroll';
import React, {
  Fragment,
  memo,
  useRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaRegFilePdf } from 'react-icons/fa';
import { useDispatch } from '../../../contexts/ResumeContext';
import DatabaseContext from '../../../contexts/DatabaseContext';
import * as styles from './LeftSidebar.module.css';
import Artboard from '../center/Artboard';
import RightSettings from '../right/RightSettings';
// import Awards from './sections/Awards';
import Certifications from './sections/Certifications';
import Education from './sections/Education';
import Hobbies from './sections/Hobbies';
import Languages from './sections/Languages';
// import LeftNavbar from './LeftNavbar';
import Objective from './sections/Objective';
import Profile from './sections/Profile';
import Projects from './sections/Projects';
import References from './sections/References';
import Skills from './sections/Skills';
import Social from './sections/Social';
import Work from './sections/Work';
import Internships from './sections/Internships';
import sections from '../../../data/leftSections';
import Completeness from '../../shared/Completeness';
import Heading from '../../shared/Heading';

const getComponent = (id) => {
  switch (id) {
    case 'profile':
      return Profile;
    case 'objective':
      return Objective;
    case 'work':
      return Work;
    case 'education':
      return Education;
    case 'social':
      return Social;
    case 'skills':
      return Skills;
    case 'projects':
      return Projects;
    case 'references':
      return References;
    case 'internships':
      return Internships;
    case 'certifications':
      return Certifications;
    case 'hobbies':
      return Hobbies;
    case 'languages':
      return Languages;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event, closeClassName, onClick }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component
          id={id}
          event={event}
          closeClassName={closeClassName}
          onClick={onClick}
        />
      </Element>
      <hr />
    </Fragment>
  );
};

const LeftSidebar = ({ resume }) => {
  const ref = useRef(null);
  const [showPreview, setShowPreview] = useState(0);
  const [showCert, setShowCert] = useState(resume.certifications.visible);
  const [showProject, setShowProject] = useState(resume.projects.visible);
  const [showHobby, setShowHobby] = useState(resume.hobbies.visible);
  const [showLang, setShowLang] = useState(resume.languages.visible);
  const [showRef, setShowRef] = useState(resume.references.visible);
  const [showInternship, setShowInternship] = useState(
    resume.internships == null ? false : resume.internships.visible,
  );
  const dispatch = useDispatch();
  const { getResume } = useContext(DatabaseContext);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
        if (getWindowDimensions().width > 1280) setShowPreview(0);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  /* const handleOpenClick = () => {
    const leftSections = ref.current;
    if (!leftSections.className.includes(styles.showleftsect))
      leftSections.className += ` ${styles.showleftsect}`;
    leftSections.style.width = `${width - 140}px`;
  }; */

  const onGoBack = () => {
    if (showPreview === 2) setShowPreview(1);
    else setShowPreview(0);
  };

  const onSettings = () => {
    setShowPreview(2);
  };

  useEffect(() => {
    // handleOpenClick();
    if (width > 1280) setShowPreview(0);
  }, []);

  useEffect(() => {
    (async () => {
      resume = await getResume(resume.id);
      console.log(resume);

      resume.certifications.visible = showCert;
      resume.projects.visible = showProject;
      resume.hobbies.visible = showHobby;
      resume.languages.visible = showLang;
      resume.references.visible = showRef;
      if (resume.internships) resume.internships.visible = showInternship;
      dispatch({ type: 'set_data', payload: resume });
    })();
  }, [showCert, showProject, showHobby, showLang, showRef, showInternship]);

  const handleCloseClick = () => {
    /* const leftSections = ref.current;
    if (leftSections.className.includes(styles.showleftsect))
      leftSections.className = styles.container; */
  };

  const handleScroll = (target) => {
    scroller.scrollTo(target, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'LeftSidebar',
    });
  };

  const handlePreview = () => {
    setShowPreview(1);
  };

  const handleAddSection = (section) => {
    switch (section) {
      case 'projects':
        setShowProject(!showProject);
        break;
      case 'references':
        setShowRef(!showRef);
        break;
      case 'internships':
        setShowInternship(!showInternship);
        break;
      case 'certifications':
        setShowCert(!showCert);
        break;
      case 'hobbies':
        setShowHobby(!showHobby);
        break;
      case 'languages':
        setShowLang(!showLang);
        break;
      default:
        return false;
    }
  };

  const visible = (id) => {
    switch (id) {
      case 'projects':
        return showProject;
      case 'references':
        return showRef;
      case 'internships':
        return showInternship;
      case 'certifications':
        return showCert;
      case 'hobbies':
        return showHobby;
      case 'languages':
        return showLang;
      default:
        return true;
    }
  };

  if (showPreview === 1) {
    return <Artboard onGoBack={onGoBack} onSettings={onSettings} />;
  }

  if (showPreview === 2) {
    return <RightSettings onGoBack={onGoBack} />;
  }

  return (
    <div className="flex flex-col">
      {/* <LeftNavbar onClick={(e) => handleOpenClick(e)} /> */}
      <h1 className={styles.h1}>{resume.name}</h1>
      <div className="flex justify-center mb-3">
        <StaticImage
          src="../../../../static/images/us.svg"
          alt="us"
          height={13}
        />
        <span className={styles.lang}>English</span>
      </div>
      <Completeness
        completeness={resume.completeness}
        doScroll={handleScroll}
      />
      <div ref={ref} id="LeftSidebar" className={styles.container}>
        {sections.map(
          (x) =>
            visible(x.id) && (
              <SidebarSection
                id={x.id}
                event={x.event}
                closeClassName={styles.btnclose}
                onClick={(e) => {
                  handleCloseClick(x, e);
                }}
              />
            ),
        )}
        <section style={{ marginBottom: '150px' }}>
          <div className="flex justify-between pt-3">
            <Heading id="addsection" />
          </div>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('certifications')}
          >
            <StaticImage
              src="../../../../static/images/section-courses.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">Courses</span>
          </button>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('projects')}
          >
            <StaticImage
              src="../../../../static/images/section-activities.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">Extra-curricular Activities</span>
          </button>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('internships')}
          >
            <StaticImage
              src="../../../../static/images/section-internships.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">Internships</span>
          </button>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('hobbies')}
          >
            <StaticImage
              src="../../../../static/images/section-hobbies.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">Hobbies</span>
          </button>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('languages')}
          >
            <StaticImage
              src="../../../../static/images/section-languages.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">Languages</span>
          </button>
          <button
            className={styles.addsection}
            onClick={() => handleAddSection('references')}
          >
            <StaticImage
              src="../../../../static/images/section-references.svg"
              alt=""
              height={40}
            />
            <span className="ml-2">References</span>
          </button>
        </section>
      </div>
      <button
        id="download"
        className={`${styles.download_button} ${styles.btnclose}`}
        onClick={handlePreview}
      >
        Preview & Download
        <FaRegFilePdf size={20} className={styles.pdf_icon} />
      </button>
    </div>
  );
};

export default memo(LeftSidebar);
