import { Element } from 'react-scroll';
import React, { Fragment, memo, useRef } from 'react';
import { BsX } from 'react-icons/bs';
import * as styles from './RightSettings.module.css';
import Actions from './sections/Actions';
import Colors from './sections/Colors';
import FontSize from './sections/FontSize';
import Fonts from './sections/Fonts';
import Layout from './sections/Layout';
// import RightNavbar from './RightNavbar';
import Settings from './sections/Settings';
import Templates from './sections/Templates';
import sections from '../../../data/rightSections';

const getComponent = (id) => {
  switch (id) {
    case 'templates':
      return Templates;
    case 'layout':
      return Layout;
    case 'colors':
      return Colors;
    case 'fonts':
      return Fonts;
    case 'actions':
      return Actions;
    case 'settings':
      return Settings;
    case 'font-size':
      return FontSize;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event, closeClassName, onClick }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component
          id={id}
          event={event}
          closeClassName={closeClassName}
          onClick={onClick}
        />
      </Element>
      <hr />
    </Fragment>
  );
};

const RightSettings = ({ onGoBack }) => {
  const ref = useRef(null);

  /* function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  const handleOpenClick = () => {
    const rightSections = ref.current;
    if (!rightSections.className.includes(styles.showrightsect))
      rightSections.className += ` ${styles.showrightsect}`;
    rightSections.style.width = `${width - 70}px`;
    rightSections.style.left = `-${width}px`;
  }; */

  const handleCloseClick = () => {
    const rightSections = ref.current;
    if (rightSections.className.includes(styles.showrightsect))
      rightSections.className = styles.container;
  };

  return (
    <>
      <div className="flex justify-end mt-5 mr-5">
        <button onClick={onGoBack}>
          <BsX size="30" />
        </button>
      </div>
      <div ref={ref} id="RightSidebar" className={styles.container}>
        {sections.map((x) => (
          <SidebarSection
            id={x.id}
            event={x.event}
            closeClassName={styles.btnclose}
            onClick={(e) => {
              handleCloseClick(x, e);
            }}
          />
        ))}
      </div>

      {/* <RightNavbar onClick={(e) => handleOpenClick(e)} /> */}
    </>
  );
};

export default memo(RightSettings);
